import React from "react";

import Logout from "../components/pages/Logout";
import ProtectedRoute from "../components/shared/ProtectedRoute";

const LogoutPage = () => <Logout />;
const ProtectedLogoutPage = (props) => (
    <ProtectedRoute {...props} component={LogoutPage} />
);

export default ProtectedLogoutPage;
