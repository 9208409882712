import React, { useEffect } from "react";

import { useKeycloakOpt, useLogout } from "../../../providers/AuthProvider";
import pushToAnalytics from "../../../utils/analytics";
import LoadingIndicator from "../../shared/LoadingIndicator";
import Margin from "../../shared/Margin";

const Logout = () => {
    const { authenticated } = useKeycloakOpt();
    const logout = useLogout();

    useEffect(() => {
        if (authenticated) {
            pushToAnalytics("auth:logout", {});
            logout();
        }
    }, [logout, authenticated]);

    return (
        <Margin top="40">
            <LoadingIndicator />
        </Margin>
    );
};

export default Logout;
